import Role from "@/models/enums/employee/role";

export const auth = {
    namespaced: true,
    state: {
        photographer_name: null,
        photographer_type: null,
        roles: [],
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_provisional_password: null,
        is_signed_in: false,

    },
    mutations: {
        //APIのコールバックでよぶには actions が必要なはずだが
        //普通に呼べている件
        setAccessToken (state, param) {
            state.photographer_id = param.photographer_id;
            state.photographer_name = param.photographer_name;
            state.photographer_type = param.photographer_type;
            state.roles = param.roles ? param.roles.map(val => parseInt(val, 10)) : [];
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_signed_in = true;
        },
        signOut (state) {
            state.photographer_id = null;
            state.photographer_name = null;
            state.photographer_type = null;
            state.roles = [];
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_signed_in = false;
        },
    },
    getters: {
        canManageSchool: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_SCHOOL) >= 0);
        },
        canManageEmployee: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_EMPLOYEE) >= 0);
        },
        canManagePartner: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_PARTNER) >= 0);
        },
        canManagePartnerPassword: (state) => () => {
            return (state.roles.indexOf(Role.MANAGE_PARTNER_PASSWORD) >= 0);
        },
    }
}
